import './components.scss';


import React from 'react';
import {PlayArrow, Pause, SkipPrevious, Replay} from '@material-ui/icons';

export default class ControlsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.play = this.play.bind(this);
		this.pause = this.pause.bind(this);
		this.stop = this.stop.bind(this);
		this.loop = this.loop.bind(this);
	}
	componentDidMount(){
		this.setState(this.props.playState);
	}
	play() {
		if(this.props.audioUnit){
			this.props.audioUnit.play();
		}
	}

	pause() {
		if(this.props.audioUnit){
			this.props.audioUnit.pause();
		}
	}

	stop() {
		if(this.props.audioUnit){
			this.props.audioUnit.stop();
		}
	}

	loop() {
		if(this.props.audioUnit){
			this.props.audioUnit.loop();
		}
	}
	render() {
		return (
			<div className="controlsContainer">
				<button className={`controlButton ${this.props.playState?.playing ? 'active' : ''}`} onClick={this.play}>
					<PlayArrow />
				</button>
				<button className="controlButton" onClick={this.pause}>
					<Pause />
				</button>
				<button className="controlButton" onClick={this.stop}>
					<SkipPrevious />
				</button>
				<button className={`controlButton ${this.props.playState?.looping ? 'active' : ''}`} onClick={this.loop}>
					<Replay />
				</button>
				</div>
			)
		
	}
}