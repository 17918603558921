import './App.css';
import MixerComponent from './components/MixerComponent.tsx';

function App() {
  return (
    <MixerComponent />
  );
}

export default App;
