import React from 'react';
import ControlsComponent from './ControlsComponent.tsx'
import AudioUnit from './AudioUnit.tsx';
import TrackComponent from './TrackComponent.tsx';
import SoloComponent from './SoloComponent.tsx';
import './components.scss';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



export default class MixerComponent extends React.Component {
	constructor(){
		super();

		this.update = this.update.bind(this);
		this.audioUnit = new AudioUnit(this.update);
	}
	update(state){
		this.setState(state);
	}
	render = ()=> {
		return (
			 <div className="App">
			 	<div>

			<Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!this.state?.loaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </div>
				 <div className="panel metal linear leftPanel">
				 	<div className="rivet tl"></div>
				 	<div className="rivet tr"></div>
				 	<div className="rivet bl"></div>
				 	<div className="rivet br"></div>
				 	<div>
		      	<ControlsComponent audioUnit={this.audioUnit} playState={this.state}/>
		      </div>
		      <div className="sliders">
			      <TrackComponent updateGain={this.audioUnit.updateGain} id={0} label="bass"/>
			      <TrackComponent updateGain={this.audioUnit.updateGain} id={1} label="drums"/>
			      <TrackComponent updateGain={this.audioUnit.updateGain} id={2} label="guitar"/>
		      </div>
	      </div>
	      <div className="rightPanel">
	      	<div className="panel metal linear">
	      	<div className="rivet tl"></div>
				 	<div className="rivet tr"></div>
				 	<div className="rivet bl"></div>
				 	<div className="rivet br"></div>
	      	<SoloComponent callback={this.update}/>
      	</div>
      	<div className="panel metal linear nameplate">
	      	<div className="rivet tl"></div>
				 	<div className="rivet tr"></div>
				 	<div className="rivet bl"></div>
				 	<div className="rivet br"></div>
	      	<h1>BloopBlues</h1>
      	</div>
	      </div>
    </div>

		)
	}
}