import React from 'react';
import {OutdoorGrillTwoTone} from '@material-ui/icons'
export default class SoloComponent extends React.Component {
	
	constructor(props){
		super(props);
		this.callback = props.callback;
		const AudioContext = window.AudioContext || window.webkitAudioContext;
		this.ctx = new AudioContext();
		this.state = {solosLoaded: false};
		this.buffers = [];
		this.mouseDown = this.mouseDown.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.checkLoad = this.checkLoad.bind(this);
		this.playRandom = this.playRandom.bind(this);
		this.getRandom = this.getRandom.bind(this);
		this.filelist = [
"audio/clip18.wav",             
"audio/clip47.wav",           
"audio/clip22.wav",             
"audio/clip51.wav",
"audio/clip03.wav",             
"audio/clip23.wav",             
"audio/clip54.wav",
"audio/clip03_1.wav",            
"audio/clip28.wav",             
"audio/clip56.wav",
"audio/clip03_2.wav",            
"audio/clip35.wav",             
"audio/clip09.wav",             
"audio/clip37.wav",             
"audio/clip15.wav",            
"audio/clip39.wav",             
"audio/clip15_1.wav",            
"audio/clip39_1.wav",
"audio/clip17.wav",             
"audio/clip46.wav"];
	
	this.filelist.forEach(fileName => {
			fetch(fileName).then(response => response.arrayBuffer()).then(buffer => this.ctx.decodeAudioData(buffer)).then(buffer => {
				this.buffers.push(buffer);
		    this.checkLoad();
			});
		});

}
	checkLoad(){
		if(this.buffers.length === this.filelist.length){
			this.state.solosLaded = true;
			this.callback(this.state);
		}
	}
	playRandom(){
		var track = this.ctx.createBufferSource();
		track.buffer = this.getRandom();
		track.connect(this.ctx.destination);
		track.start(0);
	}
	getRandom(){
		return this.buffers[Math.floor(Math.random()*this.buffers.length)];
	}
	getXPercent(val, bounds){
		var diff = (val.clientX - bounds.x ) / bounds.width;
		return diff;
	}
	getYPercent(val, bounds){
		var diff = (val.clientY - bounds.y ) / bounds.height;
		return diff;
	}
	mouseDown(event){
		var bounds = event.target.getBoundingClientRect();
		this.playing=true;
		this.tone = this.ctx.createOscillator();
		this.gain = this.ctx.createGain();
		console.log(this.getXPercent(event, bounds), this.getYPercent(event, bounds));
		this.gain.gain.setValueAtTime((1 - this.getYPercent(event, bounds)), this.ctx.currentTime);
		this.tone.type = 'square';
		this.tone.frequency.setValueAtTime(400 + (this.getXPercent(event, bounds) * 2000) , this.ctx.currentTime);
		this.tone.connect(this.gain).connect(this.ctx.destination);
		this.tone.start();
	}
	mouseMove(event){
		var bounds = event.target.getBoundingClientRect();
		
		this.gain.gain.setValueAtTime((1 - this.getYPercent(event, bounds)), this.ctx.currentTime);
		this.tone.frequency.setValueAtTime(400 + (this.getXPercent(event, bounds) * 2000) , this.ctx.currentTime);

	}	
	mouseUp(){
		if(this.tone && this.playing){
		this.tone.stop();
	}
		this.playing=false;
	}
	render() {
		return (
			<div className="solos">
				<button onClick={this.playRandom}> <OutdoorGrillTwoTone sx={{fontSize:60}} /> </button>
				<canvas className="canva" 
					width="250" 
					height="200" 
					onMouseDown={this.mouseDown}
					onMouseMove={this.mouseMove}
					onMouseUp={this.mouseUp}
					> </canvas>
			</div>
		)
	}
}