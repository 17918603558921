
export default class AudioUnit {
	constructor(callback: ()=>{}){
		this.tracks = [];
		this.callback = callback;
		const AudioContext = window.AudioContext || window.webkitAudioContext;
		this.ctx = new AudioContext();
		this.state = {playing: false, looping: false, loaded: false};
		this.play = this.play.bind(this);
		this.pause = this.pause.bind(this);
		this.stop = this.stop.bind(this);
		this.loop = this.loop.bind(this);
		this.toggleLoops = this.toggleLoops.bind(this);
		this.updateGain = this.updateGain.bind(this);
		this.loaded = 0;
		this.pauseTime=0;
		this.gainControls = [];
		for(var i = 0; i<3; i++){
			this.gainControls[i] = this.ctx.createGain();
		}
		this.buffers = [];
		if(this.loaded < 3){
			fetch("audio/bass.wav").then(response => response.arrayBuffer()).then(buffer => this.ctx.decodeAudioData(buffer)).then(buffer => {
				this.buffers[0] = buffer;
		    this.loaded++;
		    this.checkLoad();
			});
			fetch("audio/drums.wav").then(response => response.arrayBuffer()).then(buffer => this.ctx.decodeAudioData(buffer)).then(buffer => {
				this.buffers[1] = buffer;
		    this.loaded++;
		    this.checkLoad();
			});
			fetch("audio/guitar.wav").then(response => response.arrayBuffer()).then(buffer => this.ctx.decodeAudioData(buffer)).then(buffer => {
				this.buffers[2] = buffer;
		    this.loaded++;
		    this.checkLoad();
			});
		}
	}
	
	checkLoad() {
		if (this.loaded === 3) {
			this.state.loaded = true;
			this.callback(this.state);
		}
	}

	updateGain(gain, id){
		this.gainControls[id].gain.value = gain;
	}

	toggleLoops() {
		this.tracks.forEach(track => {
			track.loop = !track.loop;
		});
	}
	play() {
		if (this.ctx.state === 'suspended') {
			this.ctx.resume();
		}
		this.state.playing = true;
		this.callback(this.state);
		for(var i = 0; i<3; i ++){
			var track = this.ctx.createBufferSource();
			track.buffer = this.buffers[i];
			track.connect(this.gainControls[i]).connect(this.ctx.destination);
			track.loop = this.state.looping;
			this.tracks[i] = track;
		}
		this.tracks[0].addEventListener("ended", (event) =>{
			this.state.playing = false;
			this.callback(this.state);
		});
		
		this.tracks.forEach(track => {
			track.start(0, this.pauseTime);
		});

		this.pauseTime = 0;
	}
	pause() {
		this.state.playing = false;
		this.callback(this.state);
		this.pauseTime = this.ctx.currentTime;
		this.tracks.forEach(track => {
			track.stop();
		});
	}
	stop() {
		this.state.playing = false;
		this.callback(this.state);
		this.pauseTime = 0;
		this.tracks.forEach(track => {
			track.stop();
		});
	}
	loop() {
		this.state.looping = !this.state.looping;
		this.callback(this.state);
		this.toggleLoops();
	}

}