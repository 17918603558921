import React from 'react';
import './components.scss';

export default class TrackComponent extends React.Component {
	constructor(props){
		super(props);
		this.id = this.props.id;
		this.updateGain = this.updateGain.bind(this);
		this.label = this.props.label;
	}
	updateGain (event) {
		this.props.updateGain(event.target.value, this.id);
	}
	render() {
		return (
			<div className="fulltrackcontainer">
			<div className="trackContainer" z-index={4-this.id}>
				<input type="range" min="0" max="10" orient="vertical" onInput={this.updateGain}/>
			</div>

				<label className="tracklabel">{this.label}</label>
				</div>
		)
	}
}